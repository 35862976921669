body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Cervin';
  src: local('Cervin'), url(./fonts/Cervin.ttf) format('truetype');
}

@font-face {
  font-family: 'Klore';
  src: local('Klore'), url(./fonts/klore.ttf) format('truetype');
}   

@font-face {
  font-family: 'Kooltura';
  src: local('Kooltura'), url(./fonts/kooltura.ttf) format('truetype');
}   

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url(./fonts/HelveticaNeue.ttc) format('truetype');
}    

@font-face {
  font-family: 'RobotoLight';
  src: local('RobotoLight'), url(./fonts/RobotoLight.ttf) format('truetype');
}    

@font-face {
  font-family: 'RobotoThin';
  src: local('RobotoThin'), url(./fonts/RobotoThin.ttf) format('truetype');
} 
